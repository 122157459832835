import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const RelatorioFaturamentoModal = ({ isOpen, toggle, filial }) => {
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDownload = async (filialId) => {
    try {
      setLoading(true);
      const api = new APIClient();
      const params = {};
      
      if (dataInicio) params.data_inicio = dataInicio;
      if (dataFim) params.data_fim = dataFim;
      if (filialId) params.filial_id = filialId;

      const response = await api.postDownload("/dashboard/relatorio-faturamento", params);

      if (response.sucesso) {
        // Create blob and download
        const blob = new Blob([response.dados], {
          type: "text/csv",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        
        // Create filename with date range if provided
        let filename = "relatorio-faturamento";
        if (dataInicio) filename += `_de_${dataInicio}`;
        if (dataFim) filename += `_ate_${dataFim}`;
        filename += ".csv";
        
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        toggle();
      } else if (response.mensagem) {
        toast.error(response.mensagem);
      }
    } catch (error) {
      toast.error(error.mensagem || "Erro ao baixar relatório. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Relatório de Faturamento</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="dataInicio">Data Início</Label>
            <Input
              type="date"
              id="dataInicio"
              value={dataInicio}
              onChange={(e) => setDataInicio(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="dataFim">Data Fim</Label>
            <Input
              type="date"
              id="dataFim"
              value={dataFim}
              onChange={(e) => setDataFim(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button color="primary" onClick={() => handleDownload(filial)} disabled={loading}>
          {loading ? "Baixando..." : "Baixar Relatório"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RelatorioFaturamentoModal;
