import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardHeader, Spinner, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import RelatorioFaturamentoModal from "./RelatorioFaturamentoModal";

const VisaoGeral = ({ chartDados, filial, setFilial, userDados }) => {
  const dataColors = '["--vz-success", "--vz-primary", "--vz-danger"]';
  const [series, setSeries] = useState(null);

  var linechartcustomerColors = getChartColorsArray(dataColors);

  const [options, setOptions] = useState();
  const [filiais, setFiliais] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const fetchFiliais = useCallback(async (userDados) => {
    setFiliais(userDados.filiais);
    if (userDados.filiais.length > 0 && userDados.role !== "super-admin") {
      setFilial(userDados.filiais[0]?.id);
    }
  }, []);

  useEffect(() => {
    const updateData = async (userDados) => {
      await fetchFiliais(userDados);
      setSeries([
        {
          name: "Serviços",
          type: "area",
          data: [
            chartDados[chartDados?.meses[0]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[1]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[2]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[3]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[4]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[5]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[6]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[7]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[8]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[9]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[10]]?.servicos * 100 || 0,
            chartDados[chartDados?.meses[11]]?.servicos * 100 || 0,
          ],
        },
        {
          name: "Faturamentos",
          type: "bar",
          data: [
            chartDados[chartDados?.meses[0]]?.faturamento || 0,
            chartDados[chartDados?.meses[1]]?.faturamento || 0,
            chartDados[chartDados?.meses[2]]?.faturamento || 0,
            chartDados[chartDados?.meses[3]]?.faturamento || 0,
            chartDados[chartDados?.meses[4]]?.faturamento || 0,
            chartDados[chartDados?.meses[5]]?.faturamento || 0,
            chartDados[chartDados?.meses[6]]?.faturamento || 0,
            chartDados[chartDados?.meses[7]]?.faturamento || 0,
            chartDados[chartDados?.meses[8]]?.faturamento || 0,
            chartDados[chartDados?.meses[9]]?.faturamento || 0,
            chartDados[chartDados?.meses[10]]?.faturamento || 0,
            chartDados[chartDados?.meses[11]]?.faturamento || 0,
          ],
        },
        {
          name: "Carros",
          type: "line",
          data: [
            chartDados[chartDados?.meses[0]]?.veiculos || 0,
            chartDados[chartDados?.meses[1]]?.veiculos || 0,
            chartDados[chartDados?.meses[2]]?.veiculos || 0,
            chartDados[chartDados?.meses[3]]?.veiculos || 0,
            chartDados[chartDados?.meses[4]]?.veiculos || 0,
            chartDados[chartDados?.meses[5]]?.veiculos || 0,
            chartDados[chartDados?.meses[6]]?.veiculos || 0,
            chartDados[chartDados?.meses[7]]?.veiculos || 0,
            chartDados[chartDados?.meses[8]]?.veiculos || 0,
            chartDados[chartDados?.meses[9]]?.veiculos || 0,
            chartDados[chartDados?.meses[10]]?.veiculos || 0,
            chartDados[chartDados?.meses[11]]?.veiculos || 0,
          ],
        },
      ]);

      setOptions({
        chart: {
          height: 370,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "straight",
          dashArray: [0, 0, 8],
          width: [2, 0, 2.2],
        },
        fill: {
          opacity: [0.1, 0.9, 1],
        },
        markers: {
          size: [0, 0, 0],
          strokeWidth: 2,
          hover: {
            size: 4,
          },
        },
        xaxis: {
          categories: chartDados?.meses,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: -2,
            bottom: 15,
            left: 10,
          },
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: -5,
          markers: {
            width: 9,
            height: 9,
            radius: 6,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
            barHeight: "70%",
          },
        },
        colors: linechartcustomerColors,
        tooltip: {
          shared: true,
          y: [
            {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return (y / 100).toFixed(0);
                }
                return y;
              },
            },
            {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "R$" + y.toFixed(2);
                }
                return y;
              },
            },
            {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0);
                }
                return y;
              },
            },
          ],
        },
      });
    };
    if (chartDados) {
      updateData(userDados);
    }
  }, [chartDados, userDados, filial]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Visão Geral</h4>
          <div className="d-flex gap-1">
            <select
              className="form-control me-2 form-select form-select-sm"
              value={filial || ""}
              onChange={(e) =>
                setFilial(
                  e.target.value && e.target.value != "0"
                    ? Number(e.target.value)
                    : null
                )
              }
            >
              <option value="">Selecione uma filial</option>
              <option value="0">Todas</option>
              {filiais.map((filial, key) => {
                return (
                  <option key={key} value={filial.id}>
                    {filial.nome}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="d-flex gap-1">
            <Button
              type="button"
              className="btn btn-soft-secondary btn-sm shadow-none"
              onClick={toggleModal}
            >
              Ver Relatório Completo
            </Button>
          </div>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            {!options || !series ? (
              <Spinner color="primary" />
            ) : (
              <div dir="ltr">
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={series}
                  type="line"
                  height="370"
                  className="apex-charts"
                />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
      <RelatorioFaturamentoModal isOpen={isModalOpen} toggle={toggleModal} filial={filial} />
    </React.Fragment>
  );
};

export default VisaoGeral;
