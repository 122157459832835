import React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const TotalClientes = ({ series, options }) => {
  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Total Clientes</h4>
          </CardHeader>
          <CardBody className="p-0 pb-2">
            <div>
              <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type="bar"
                height="309"
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TotalClientes;
