import React from "react";
import { Card, CardHeader, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const Faturamento = ({ series, options }) => {
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Faturamento</h4>
          </CardHeader>

          <ReactApexChart
            dir="ltr"
            options={options}
            series={series}
            type="donut"
            height="333"
            className="apex-charts"
          />
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Faturamento;
