import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Widget from "./Widgets";
import VisaoGeral from "./VisaoGeral";
import Faturamento from "./Faturamento";
import TotalClientes from "./TotalClientes";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
const apiClient = new APIClient();
import { toast } from "react-toastify";

const Dashboard = () => {
  document.title = `Dashboard | ${process.env.REACT_APP_TITLE}`;
  const [filial, setFilial] = useState(null);
  const [userDados, setUserDados] = useState(null);
  const [chartDados, setChartDados] = useState(null);

  var chartDonutBasicColors = getChartColorsArray(
    '["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
  );

  const [seriesFaturamento, setSeriesFaturamento] = useState([0, 0, 0, 0, 0]);
  const [optionsFaturamento, setOptionsFaturamento] = useState(null);

  const [seriesClientes, setSeriesClientes] = useState([
    {
      name: "Clientes",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);

  const dataColors = '["--vz-success", "--vz-light"]';

  var chartAudienceColumnChartsColors = getChartColorsArray(dataColors);
  const [optionsClientes, setOptionsClientes] = useState({
    chart: {
      type: "bar",
      height: 309,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontWeight: 400,
      fontSize: "8px",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
        radius: 4,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      show: false,
    },
    colors: chartAudienceColumnChartsColors,
    xaxis: {
      categories: [],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        strokeDashArray: 1,
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  });

  const fetchChart = useCallback(async () => {
    const userData = await getLoggedinUser();
    setUserDados(userData);
    let data = {};

    if (filial) {
      data.filial_id = filial;
    } else if (userData?.role !== "super-admin") {
      const filial_id =
        userData?.filiais.length > 0 ? userData.filiais[0].id : null;
      setFilial(filial_id);
      data.filial_id = filial_id;
    }

    const response = await apiClient.post("/dashboard/dados-por-mes", data);
    if (response.sucesso) {
      const dados = response.dados;
      setChartDados(dados);
      setSeriesClientes([
        {
          name: "Clientes",
          data: [
            dados[dados?.meses[0]]?.clientes || 0,
            dados[dados?.meses[1]]?.clientes || 0,
            dados[dados?.meses[2]]?.clientes || 0,
            dados[dados?.meses[3]]?.clientes || 0,
            dados[dados?.meses[4]]?.clientes || 0,
            dados[dados?.meses[5]]?.clientes || 0,
            dados[dados?.meses[6]]?.clientes || 0,
            dados[dados?.meses[7]]?.clientes || 0,
            dados[dados?.meses[8]]?.clientes || 0,
            dados[dados?.meses[9]]?.clientes || 0,
            dados[dados?.meses[10]]?.clientes || 0,
            dados[dados?.meses[11]]?.clientes || 0,
          ],
        },
      ]);
      setOptionsClientes({
        chart: {
          type: "bar",
          height: 309,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            borderRadius: 6,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: 400,
          fontSize: "8px",
          offsetX: 0,
          offsetY: 0,
          markers: {
            width: 9,
            height: 9,
            radius: 4,
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        grid: {
          show: false,
        },
        colors: chartAudienceColumnChartsColors,
        xaxis: {
          categories: dados?.meses,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: true,
            strokeDashArray: 1,
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
      });
      // series faturamento são só os 5 primeiros itens
      setSeriesFaturamento([
        dados[dados?.meses[0]]?.faturamento || 0,
        dados[dados?.meses[1]]?.faturamento || 0,
        dados[dados?.meses[2]]?.faturamento || 0,
        dados[dados?.meses[3]]?.faturamento || 0,
        dados[dados?.meses[4]]?.faturamento || 0,
      ]);
      setOptionsFaturamento({
        labels: dados?.meses.slice(0, 5),
        chart: {
          height: 333,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
        },
        colors: chartDonutBasicColors,
      });
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, [filial]);

  useEffect(() => {
    const fetchAll = async () => {
      await fetchChart();
      const data = await getLoggedinUser();
      if (data?.role !== "super-admin" && data?.role !== "admin") {
        window.location.href = "/ordens-servico";
      }
    };

    fetchAll();
  }, [fetchChart, filial]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widget filial={filial}/>
                </Row>
                <Row>
                  <Col xl={12}>
                    <VisaoGeral
                      chartDados={chartDados}
                      filial={filial}
                      setFilial={setFilial}
                      userDados={userDados}
                    />
                  </Col>
                </Row>
                <Row>
                  {!optionsFaturamento ? (
                    <Spinner />
                  ) : (
                    <Faturamento
                      series={seriesFaturamento}
                      options={optionsFaturamento}
                    />
                  )}
                  {!optionsClientes ? (
                    <Spinner />
                  ) : (
                    <TotalClientes
                      series={seriesClientes}
                      options={optionsClientes}
                    />
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
